import React from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import "../styles/layout.css"

const styles = {
  root: {
    alignContent: "center",
    color: "#1f1f1f",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    fontFamily: "roboto, sans-serif",
  },
  content: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "32px",

    h1: {
      fontSize: "42px",
      marginBottom: "8px",
    },

    p: {
      fontSize: "24px",
      marginBottom: 16,
    },

    a: {
      color: "#1f1f1f",
    },
  },
  socialList: {
    listStyle: "none",
    margin: "0",
    padding: 0,

    li: {
      display: "inline-block",
      fontSize: "24px",
      marginRight: "16px",
    },
  },
}

export default function Home() {
  return (
    <>
      <Helmet title="Home | Juan Davila" defer={false} />
      <div css={styles.root}>
        <div css={styles.content}>
          <h1>Hello! My name is Juan Davila.</h1>
          <p>
            I am a product designer who loves cycling, the outdoors and
            photography. I am currently based out of Toronto, Canada.
          </p>
          <p>
            <a href="mailto:hello@juandavila.com">Get in touch</a> or check me
            out on <a href="https://www.instagram.com/juanster/">Instagram</a>{" "}
            and <a href="https://www.linkedin.com/in/juandav/">LinkedIn</a>.
          </p>
        </div>
      </div>
    </>
  )
}
